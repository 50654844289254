/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
// import Slider from 'react-slick';
import { EventType } from 'types';
import Moment from 'react-moment';
import './eventGridSlider.scss';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import {
//   faChevronLeft,
//   faChevronRight,
// } from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import Loader from '../loader/Loader';

interface EventGridSliderProps {
  displayEventsStyleGridOrSlider?: string;
}

const GET_EVENTS = gql`
  query GET_EVENTS {
    events(first: 100, orderBy: eventStartDateTime_ASC) {
      eventAddress
      eventAddressGoogleMapLink
      eventCityState
      eventEndDateTime
      eventDescription {
        html
      }
      eventFeatured
      eventFlyer {
        id
        url
      }
      eventLinkButtonText
      eventShortDescription
      eventSlug
      eventStartDateTime
      eventTicketLinkDestination
      eventTitle
      eventVenueName
    }
  }
`;

// function PrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//       }}
//       role="button"
//       aria-label="Press enter to view previous item"
//       tabIndex={0}
//       onClick={onClick}
//       onKeyPress={onClick}>
//       <FontAwesomeIcon icon={faChevronLeft} />
//     </div>
//   );
// }

// function NextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{
//         ...style,
//       }}
//       role="button"
//       aria-label="Press enter to view next item"
//       tabIndex={0}
//       onClick={onClick}
//       onKeyPress={onClick}>
//       <FontAwesomeIcon icon={faChevronRight} />
//     </div>
//   );
// }

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 1000,
//   autoplaySpeed: 2000,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   swipeToSlide: true,
//   autoplay: true,
//   nextArrow: <NextArrow />,
//   prevArrow: <PrevArrow />,
//   responsive: [
//     {
//       breakpoint: 800,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };

export default function EventGridSlider(props: EventGridSliderProps) {
  const { loading, data } = useQuery(GET_EVENTS);
  if (loading) return <Loader height="60vh" />;
  const { events } = data;
  const { displayEventsStyleGridOrSlider } = props;

  return (
    <div>
      {(displayEventsStyleGridOrSlider === 'grid' ||
        displayEventsStyleGridOrSlider === undefined) && (
        <div className="grid w-10 mx-auto">
          {events.map((event: EventType) => (
            <Link
              to={`/event/${event.eventSlug}`}
              className="col-12 md:col-5 lg:col-4 flex flex-row no-underline relative event-grid-item align-items-center"
              key={event.eventSlug}>
              <div className="date-format-badge">
                <span className="date-format-badge-month">
                  <Moment format="MMM">{event.eventStartDateTime}</Moment>
                </span>
                <span className="date-format-badge-date">
                  <Moment format="DD">{event.eventStartDateTime}</Moment>
                </span>
              </div>
              <p className="text-left uppercase flex flex-column pt-0 my-0 py-0 ml-2">
                <span className="text-md">{event.eventTitle}</span>
                <span className="text-sm opacity-80">
                  {event.eventCityState}
                </span>
              </p>
            </Link>
          ))}
        </div>
      )}
      {/* {displayEventsStyleGridOrSlider === 'slider' && (
        <div className="event-slider-wrapper">
          <Slider {...settings} className="">
            {events.map((event: EventType) => (
              <Link
                to={`/event/${event.eventSlug}`}
                className="w-6 flex flex-column no-underline relative mx-auto"
                key={event.eventSlug}>
                <div className="date-format-badge">
                  <span className="date-format-badge-month">
                    <Moment format="MMM">{event.eventStartDateTime}</Moment>
                  </span>
                  <span className="date-format-badge-date">
                    <Moment format="DD">{event.eventStartDateTime}</Moment>
                  </span>
                </div>
                <img
                  src={event?.eventFlyer?.url}
                  alt={event.eventTitle}
                  className="block w-full mb-2 border-round"
                />
                <span className="text-center text-link uppercase">
                  {event.eventTitle}
                </span>
              </Link>
            ))}
          </Slider>
        </div>
      )} */}
      {displayEventsStyleGridOrSlider === 'slider' && (
        <div className="event-slider-wrapper">
          <Swiper
            effect="coverflow"
            grabCursor
            centeredSlides
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper">
            {events.map((event: EventType) => (
              <SwiperSlide key={event.eventSlug}>
                <Link
                  to={`/event/${event.eventSlug}`}
                  className="block no-underline relative mx-auto">
                  <div className="date-format-badge">
                    <span className="date-format-badge-month">
                      <Moment format="MMM">{event.eventStartDateTime}</Moment>
                    </span>
                    <span className="date-format-badge-date">
                      <Moment format="DD">{event.eventStartDateTime}</Moment>
                    </span>
                  </div>
                  <img
                    src={event?.eventFlyer?.url}
                    alt={event.eventTitle}
                    className="block w-full mb-2 border-round"
                  />
                  <span className="text-center text-link uppercase block">
                    {event.eventTitle}
                  </span>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
}
